import Header from '../components/Header'

const Contact: any = () => {
    return (
        <>
            <Header />
            <h1>Contact page</h1>
        </>
    )
}

export default Contact;