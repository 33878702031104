import { Link } from "react-router-dom"
import { Navbar, Container, Nav, NavDropdown } from "react-bootstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCoffee } from "@fortawesome/free-solid-svg-icons"
import { faGithub, faDev, faLinkedin, faTwitter } from "@fortawesome/free-brands-svg-icons"
import './header.css'

export default () => {
    return (
        <>


            <Navbar fixed="top" collapseOnSelect expand="sm" bg="light" variant="light">
                <Container>
                    <Navbar.Brand id="branding" href="#home">JB</Navbar.Brand>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Navbar.Collapse className="justify-content-end" id="responsive-navbar-nav">
                        <Nav>
                            <Nav.Link href="https://twitter.com/thatonejakeb" target="_blank" className="twitter"><FontAwesomeIcon size="2x" icon={faTwitter} /></Nav.Link>
                            <Nav.Link href="https://www.linkedin.com/in/jacob-baker-21341737/" target="_blank" className="linkedin"><FontAwesomeIcon size="2x" icon={faLinkedin} /></Nav.Link>
                            <Nav.Link href="https://dev.to/thatonejakeb" target="_blank"><FontAwesomeIcon size="2x" icon={faDev} /></Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </>
    )
}