import { Button, Col, Container, Row } from 'react-bootstrap';
import Header from '../components/Header'
import './Home.css'
import EmojiPeopleIcon from '@material-ui/icons/EmojiPeople'
import { faTwitter, faLinkedin, faDev } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-dom'
import NobbleAnimation from '../components/Nobble'
import { useEffect } from 'react'
import Projects from './Projects';
import Footer from './Footer';

const Home: any = () => {
    useEffect(() => {
        // const canvas: HTMLCanvasElement | null = document.getElementById('draw-area') as HTMLCanvasElement;

        // console.log(canvas);

        // new NobbleAnimation(canvas!);
    });
    return (
        <>
            <Header />
            <Container fluid>
                {/* <Row className="justify-content-center min-vh-100"> */}

                <Row className="d-flex flex-grow-1 flex-fill justify-content-center align-items-center justify-content-center min-vh-100">

                    <Col xsHidden md={4} className="align-items-center justify-content-center text-center d-none d-md-block">
                        <img src="/icon.jpg" height="400" style={{ "borderRadius": "30%" }} />
                        {/* <canvas id="draw-area" height="200" width="200"></canvas> */}
                    </Col>
                    <Col md={4} className="align-items-center">
                        <Row>
                            <Row><h1>I'm Jacob,</h1></Row>
                            <Row><h2>Lead Software Developer.</h2></Row>
                            <Row><h2>Associate Lecturer.</h2></Row>
                            <Row><h2>Mentor.</h2></Row>
                        </Row>
                        <Row p0 className="justify-content-start">
                            <Col xs={"auto"}>
                                <a href="#projects"><Button variant="outline-secondary">Learn More</Button></a>
                            </Col>
                            <Col xs={"auto"}>
                                <a href="https://www.linkedin.com/in/jacob-baker-21341737/" target="_blank"><Button>Say hi <EmojiPeopleIcon /></Button></a>
                            </Col>
                        </Row>

                    </Col>
                </Row>
                {/* </Row> */}
            </Container>
            <Projects />
            <Footer />
        </>
    )
}

export default Home;