import { Container, Row, Col } from "react-bootstrap"
import ColorizeIcon from '@material-ui/icons/Colorize'
import { faEyeDropper, faEgg, faSeedling, faLightbulb, faBook, faDiagramProject } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import "./Projects.css"

export default () => {
    return (
        <>
            <a id="projects"></a>
            <Container>
                <Row className="justify-content-center align-items-flex-start  min-vh-100">
                    <Col className="project" lg={6} onClick={() => console.log("liquid")}>
                        <Row><FontAwesomeIcon icon={faEyeDropper} size={"4x"} /></Row>
                        <Row p-3 className="p-4 text-center">
                            <h3>Automated Liquid Handler</h3>
                        </Row>
                        <Row>
                            <p>Led the embedded software development and initial product launch of a benchtop automated liquid handling system using <b>C++</b> and <b>Python</b>, with <b>embedded Lua</b> scripting, for system control and <b>Node.js (TypeScript)</b> for integrated system configuration.</p>
                        </Row>
                        <Row>
                            <p>Uses a custom <b>Linux</b> image built using <b>Yocto</b> based on <b>Debian 10</b>.</p>
                        </Row>
                    </Col>
                    <Col className="project" lg={6}>
                    <Row><FontAwesomeIcon icon={faEgg} size={"4x"} /></Row>
                        <Row className="p-4 text-center">
                            <h3>Consumer IoT Product & Mobile App</h3>
                        </Row>
                        <Row>
                            <p>Led the front and backend development of a new product initiative for a client entering the IoT space using <b>AWS infrastructure as code</b>, <b>Firebase Cloud Messaging</b>, and <b>React Native</b> to provide a view of live data, settings configuration, device onboarding, and alarm push notifications.</p>
                        </Row>
                        <Row>
                            <p>Included automated testing using <b>Jest</b>, <strong>Detox</strong>, and <strong>AWS client mock</strong> as part of a <b>CI/CD</b> process with <b>AWS Code Build</b> to <b>S3</b> and <b>MS App Center</b> to allow for a tight client feedback loop.</p>
                        </Row>
                    </Col>
                    <Col className="project" lg={6}>
                    <Row><FontAwesomeIcon icon={faSeedling} size={"4x"} /></Row>
                        <Row className="p-4 text-center">
                            <h3>Environmental Monitoring Platform</h3>
                        </Row>
                        <Row>
                            <p>Returning developer, and previous lead, of a <b>PHP</b>, <b>React (TypeScript)</b>, <b>Python</b>, project that measures, processes, and visualises; noise, vibration, dust, and meteorological data.</p>
                        </Row>
                        <Row>
                            <p>Led the on-time delivery and successful release of three major updates to the live web application allowing the client to integrate new monitoring instruments and expand their business in to Europe.</p>
                        </Row>
                        <Row>
                            <p>Makes use of a number of automation technologies including <b>Ansible</b> and <b>AWX</b>, <b>Bitbucket CI Pipelines</b>, <b>AWS ECR</b>.</p>
                        </Row>
                    </Col>
                    <Col className="project" lg={6}>
                    <Row><FontAwesomeIcon icon={faLightbulb} size={"4x"} /></Row>
                        <Row className="p-4 text-center">
                            <h3>Lighting Control System</h3>
                        </Row>
                        <Row>
                            <p>Led the onboarding and development of a lighting control system, in <b>C</b>, using <b>PIC microcontrollers</b>, to provide extended output capabilities and a low-level communication protocol for lighting and associated data.</p>
                        </Row>
                        <Row>
                            <p>Protocol designed to work over existing power cabling in locations, over long distances, to allow for easy retrofit installations.</p>
                        </Row>
                        <Row>
                            <p>Currently being used on cruise ships and event locations in Holland.</p>
                        </Row>
                    </Col>
                    <Col className="project" lg={6}>
                    <Row><FontAwesomeIcon icon={faBook} size={"4x"} /></Row>
                        <Row className="p-4 text-center">
                            <h3>Aircraft Data Logger</h3>
                        </Row>
                        <Row>
                            <p>Designed and implemented a standalone, external, data logging system for aircrafts with onboard buffering and unidirectional synchronisation to remote server over <b>3G/4G</b> and prototyped use of <b>Iridium Satellite</b> on an <b>iMX7 IoT SBC</b>.</p>
                        </Row>
                    </Col>
                    <Col className="project" lg={6}>
                    <Row><FontAwesomeIcon icon={faDiagramProject} size={"4x"} /></Row>
                        <Row className="p-4 text-center">
                            <h3>Project Management</h3>
                        </Row>
                        <Row>
                            <p>Led the adoption of <b>Agile</b> practices using <b>Jira</b> across all projects, and have directly acted as <b>Product Manager</b> on a number of projects directly working with clients to determine requirements and break them down in to <b>User Stories</b>.</p>
                        </Row>
                        <Row>
                            <p>Interviewed new team members and represented the company at various business and recruitment fairs.</p>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </>
    )
}