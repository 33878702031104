import { Container, Row } from "react-bootstrap"
import { faHeart } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { fontSize } from "@mui/system"

export default () => {
    return (
        <Container>
            <Row className="pb-3 mt-3 text-center">
                <small>&copy; Jacob Baker <a href="https://www.youtube.com/watch?v=1wnE4vF9CQ4" target="_blank"><FontAwesomeIcon icon={faHeart} style={{ marginLeft: "5px", color: "#000", fontSize: "10px" }} /></a></small>
            </Row>
        </Container>
    )
}